/*
 * @Author: dongjia
 * @Date: 2021-08-23 17:16:36
 * @LastEditTime: 2021-08-23 17:22:48
 * @LastEditors: aleaner
 * @Description: 评论详情接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\comment-admin\detail.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取评论列表
export const commentDetail = data => {
  return api({
    url: "/admin/circle/comment/detail",
    method: "post",
    data
  });
};